import { OnStockWarehouseSummaryItemBlock } from '@/modules/onstock'
import Vue, { CreateElement, VNode } from 'vue'
import { ModelStateNumber } from '../../../store/modelViewer.store'
import SelectTreeNode from './SelectTreeNode.vue'
import SelectTreeNodeWithoutChildren from './SelectTreeNodeWithoutChildren.vue'

export interface CompareStates {
  deleted: boolean
  added: boolean
  changed: boolean
}

export enum OptimizationTreeTooltipType {
  WarehouseOpt,
  SupplyOpt,
}

export interface WarehouseOptimizationTreeNode {
  id: string
  name: string
  value: number
  valueToCompare?: number
  quantity?: number
  quantityToCompare?: number
  warehouseSummaryItemBlocks?: OnStockWarehouseSummaryItemBlock[]
  warehouseSummaryItemBlocksToCompare?: OnStockWarehouseSummaryItemBlock[]
  // Ignored if node does not have children
  open?: boolean
  hidden?: boolean
  modelState?: ModelStateNumber
  compare?: boolean
  children?: WarehouseOptimizationTreeNode[]
  hideProgress?: boolean
  tooltipType?: OptimizationTreeTooltipType
  addSeparator?: boolean
  addUnusedValue?: number
  afterIcon?: { name: string; color: string; size: number }
  objectMatchId?: number
  deleteMatchHandler?: (id: number) => Promise<void>
}

export default Vue.extend({
  name: 'WarehouseOptimizationSelectTree',
  props: {
    items: {
      type: Array as () => WarehouseOptimizationTreeNode[],
      required: true,
    },
    selectedIds: {
      type: Array as () => string[],
      default: () => [],
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    selectedColor: {
      type: String,
      default: '#5aaeff',
    },
  },
  render(h: CreateElement) {
    Vue.component('SelectTreeNode', SelectTreeNode)
    Vue.component('SelectTreeNodeWithoutChildren', SelectTreeNodeWithoutChildren)

    function getChildIds(items: WarehouseOptimizationTreeNode[]): string[] {
      return items.flatMap((child) => {
        return child.children?.length ? getChildIds(child.children) : child.id
      })
    }

    const sumUsedValuesRec = (
      node: WarehouseOptimizationTreeNode,
      modelState?: ModelStateNumber
    ): number => {
      if (!node.children) {
        if (node.modelState != null) {
          return node.modelState == modelState ? node.value : 0
        }

        return node.value
      }

      const result = node.children
        .map((child) => {
          return sumUsedValuesRec(child, modelState)
        })
        .reduce((sum, current) => sum + current, 0)

      return result
    }

    const sumUsedQuantity = (
      node: WarehouseOptimizationTreeNode,
      modelState?: ModelStateNumber
    ): number => {
      if (!node.children) return 0

      if (node.modelState != null) {
        return node.modelState == modelState
          ? node.children.filter((child) => child.modelState == modelState).length
          : 0
      }

      return node.children.length
    }

    const renderNodes = (nodes: WarehouseOptimizationTreeNode[], level: number): VNode[] => {
      return nodes.map((node) => {
        const children = node.children ? renderNodes(node.children, level + 1) : undefined

        return node.children
          ? h(
              'select-tree-node',
              {
                props: {
                  item: node,
                  usedValue: sumUsedValuesRec(node, ModelStateNumber.primary),
                  usedQuantity: sumUsedQuantity(node, ModelStateNumber.primary),
                  usedValueSecondary: node.compare
                    ? sumUsedValuesRec(node, ModelStateNumber.secondary)
                    : undefined,
                  usedQuantitySecondary: node.compare
                    ? sumUsedQuantity(node, ModelStateNumber.secondary)
                    : undefined,
                  level,
                  singleSelect: this.singleSelect,
                  selected: getChildIds(node.children).some((childId) =>
                    this.selectedIds.includes(childId)
                  ),
                  color: this.selectedColor,
                },
                on: {
                  delete: async () => {
                    this.$emit('delete', node)
                  },
                  select: () => {
                    // Multi select
                    if (!node.children) return

                    const childIds = getChildIds(node.children)

                    const allChildrenSelected = childIds.every((childId) =>
                      this.selectedIds.includes(childId)
                    )

                    allChildrenSelected
                      ? this.$emit('remove', ...childIds)
                      : this.$emit('select', ...childIds)
                  },
                },
              },
              children
            )
          : h('select-tree-node-without-children', {
              props: {
                item: node,
                level,
                selected: this.selectedIds.includes(node.id),
                color: this.selectedColor,
              },
              on: {
                delete: async () => {
                  this.$emit('delete', node)
                },
                select: (id: string) => {
                  if (this.singleSelect && !this.selectedIds.includes(node.id)) {
                    this.$emit('remove', ...this.selectedIds)
                  }
                  this.$emit(this.selectedIds.includes(node.id) ? 'remove' : 'select', id)
                },
              },
            })
      })
    }

    const ret = h(
      'div',
      {
        class: ['select-tree', 'pa-3'],
        style: {
          width: '100%',
        },
      },
      renderNodes(this.items, 0)
    )

    return ret
  },
})

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    [
      _vm.badgeContent > 0
        ? _c(
            "v-badge",
            {
              attrs: {
                "offset-x": 10,
                "offset-y": 15,
                content: _vm.badgeContent,
                color: _vm.badgeColor,
              },
            },
            [_c("chat-svg")],
            1
          )
        : _c("chat-svg"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
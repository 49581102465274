
import { CsDialog } from '@consteel/csuetify'
import Vue from 'vue'

export interface OptimizationDialogProgress {
  text: string
  value: number
  valuePortion: number
  valueSolver: number

  progressPortion: number
  progressSolver: number
  progress: number

  details: string
  detailsPortion?: string
  detailsSolver?: string

  indeterminate?: boolean
  error?: boolean
  hide?: boolean
}

export default Vue.extend({
  name: 'OptimizationProgressDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    progresses: {
      type: Array as () => OptimizationDialogProgress[],
    },
    currentProgressIndex: {
      type: Number,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentProgress(): OptimizationDialogProgress | undefined {
      return this.progresses.find((progress) => progress.value === this.currentProgressIndex)
    },
  },
  components: {
    CsDialog,
  },
  methods: {
    isErrorOccured(progress: OptimizationDialogProgress): boolean {
      return !!progress.error
    },
    isFulfilled(progress: OptimizationDialogProgress): boolean {
      return progress.progress === 100
    },
    isLoading(progress: OptimizationDialogProgress): boolean {
      return progress.value === this.currentProgressIndex
    },
    isDisabled(progress: OptimizationDialogProgress): boolean {
      return progress.value > this.currentProgressIndex
    },
  },
})


import {
  exportWarehouseOptimization,
  OnStockModel,
  onStockStore,
  OnStockWarehouseOptimization,
  setFetchedWarehouseOptimization,
  SummaryType,
  warehouseOptimizationItems,
} from '@/modules/onstock'
import {
  addItemsToSelection,
  getCompareStateBasedOnFadingLevel,
  ModelStateNumber,
  modelViewerStore,
  removeItemsFromSelection,
} from '@/store/modelViewer.store'
import { CsBtn, CsFlex, CsRadio, CsRadioGroup, CsSelect, CsWarningDialog } from '@consteel/csuetify'
import { Model } from '@consteel/smadsteelloader'
import { CacheContainer } from '@consteel/storm'
import Vue from 'vue'
import WarehouseOptimizationDialog from '../Dialogs/WarehouseOptimizationDialog/WarehouseOptimizationDialog.vue'
import EmptyWarehouseOptimization from '../EmptyWarehouseOptimization.vue'
import ModelStateChip from './ModelStateChip.vue'
import WarehouseOptimizationSelectTree, {
  WarehouseOptimizationTreeNode,
} from './WarehouseOptimizationSelectTree.vue'

export default Vue.extend({
  name: 'WarehouseOptimizationTab',
  components: {
    CsFlex,
    CsBtn,
    WarehouseOptimizationSelectTree,
    WarehouseOptimizationDialog,
    CsRadio,
    CsRadioGroup,
    CsSelect,
    ModelStateChip,
    EmptyWarehouseOptimization,
    CsWarningDialog,
  },
  data() {
    return {
      cache: new CacheContainer(),
      secondaryCache: new CacheContainer(),
      filter: SummaryType.Section as SummaryType,
      loading: false,
      warehouseOptimizationDialog: false,
      selectedPortionId: null as string | null,
      showDeleteObjectMatchNodeDialog: false,
      deletingObjectMatchNode: null as WarehouseOptimizationTreeNode | null,
      doNotAskAgainDelete: false,
    }
  },
  async mounted() {
    await setFetchedWarehouseOptimization(this.isCompare, this.$route.params.historyId)
  },
  computed: {
    warehouseStartBtnDisabled(): boolean {
      return this.loading || !!this.$route.params.historyId
    },
    portionItems(): Array<{ text: string | undefined; value: string | null | undefined }> {
      if (!this.warehouseOptimization) return []

      return [
        ...this.warehouseOptimization.warehouseOptimizationPortions.map((portion) => ({
          text: portion.portionName,
          value: portion.portionSmadsteelId,
        })),
        { text: 'Teljes modell', value: null },
      ]
    },
    activeModelState(): ModelStateNumber {
      return getCompareStateBasedOnFadingLevel()
    },
    activeModelNumber(): string {
      return getCompareStateBasedOnFadingLevel() === ModelStateNumber.secondary
        ? modelViewerStore.secondaryModel?.modelNumber || ''
        : modelViewerStore.model.modelNumber || ''
    },
    isEmpty(): boolean {
      return !onStockStore.warehouseOptimization && !onStockStore.warehouseOptimizationSecondary
    },
    isCompare(): boolean {
      return modelViewerStore.activeMainTab == 1
    },
    SectionFilterValue(): SummaryType {
      return SummaryType.Section
    },
    MaterialFilterValue(): SummaryType {
      return SummaryType.Material
    },
    warehouseOptimizationDate(): string {
      let date = new Date()

      if (this.isCompare && this.warehouseOptimization && this.warehouseOptimizationSecondary) {
        date =
          getCompareStateBasedOnFadingLevel() === ModelStateNumber.primary
            ? this.warehouseOptimization.creationDate
            : this.warehouseOptimizationSecondary.creationDate
      } else if (this.warehouseOptimization) {
        date = this.warehouseOptimization.creationDate
      }

      return Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(date))
    },
    warehouseOptimizationPriorityData(): Record<string, string> {
      return {
        LENGTH: 'Hossz',
        INVENTORY_DATE: 'Dátum',
        WEIGHT: 'Súly',
      }
    },
    warehouseOptimizationMatchingData(): Record<string, string> {
      return {
        FAST_APPROXIMATION: 'Közelítő',
        SLOW_ACCURATE: 'Pontos',
      }
    },
    warehouseOptimizationMainPriority(): string {
      return this.warehouseOptimization?.mainPriority
        ? this.warehouseOptimizationPriorityData[this.warehouseOptimization?.mainPriority]
        : ''
    },
    warehouseOptimizationSecondaryPriority(): string {
      return this.warehouseOptimization?.secondaryPriority
        ? this.warehouseOptimizationPriorityData[this.warehouseOptimization?.secondaryPriority]
        : ''
    },
    warehouseOptimizationMatchingMethod(): string {
      return this.warehouseOptimization?.matchingMethod
        ? this.warehouseOptimizationMatchingData[this.warehouseOptimization?.matchingMethod]
        : ''
    },
    modelFile(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
    onStockModel(): OnStockModel | null {
      return onStockStore.onStockModel
    },
    modelFileSecondary(): Model | undefined | null {
      return modelViewerStore.secondaryModel.rawSmadsteelModel
    },
    items(): WarehouseOptimizationTreeNode[] {
      return warehouseOptimizationItems(
        this.warehouseOptimization,
        this.warehouseOptimizationSecondary,
        this.modelFile || null,
        this.modelFileSecondary || null,
        this.filter,
        this.isCompare,
        this.selectedPortionId
      )
    },
    warehouseOptimization(): OnStockWarehouseOptimization | null {
      return onStockStore.warehouseOptimization
    },
    warehouseOptimizationSecondary(): OnStockWarehouseOptimization | null {
      return onStockStore.warehouseOptimizationSecondary
    },
    selectedIds(): string[] {
      return modelViewerStore.selectedIds
    },
  },
  watch: {
    async warehouseOptimization() {
      this.selectedPortionId =
        this.warehouseOptimization?.warehouseOptimizationPortions[0]?.portionSmadsteelId || null
    },
    async modelFile(): Promise<void> {
      await setFetchedWarehouseOptimization(this.isCompare, this.$route.params.historyId)
    },
    async onstockModel(): Promise<void> {
      await setFetchedWarehouseOptimization(this.isCompare, this.$route.params.historyId)
    },
    model(): void {
      this.cache.Clear()
    },
    modelFileSecondary(): void {
      this.secondaryCache.Clear()
    },
  },
  methods: {
    async onClickDeleteObjectMatchNode(node: WarehouseOptimizationTreeNode) {
      this.deletingObjectMatchNode = node
      if (this.doNotAskAgainDelete && node.objectMatchId) {
        await this.deletingObjectMatchNode?.deleteMatchHandler?.(node.objectMatchId)
      } else {
        this.showDeleteObjectMatchNodeDialog = true
      }
    },
    async onClickApproveDeleteObjectMatchNode(doNotAskAgain: boolean) {
      this.doNotAskAgainDelete = doNotAskAgain
      this.showDeleteObjectMatchNodeDialog = false
      if (this.deletingObjectMatchNode?.objectMatchId) {
        await this.deletingObjectMatchNode?.deleteMatchHandler?.(
          this.deletingObjectMatchNode.objectMatchId
        )
      }
      this.deletingObjectMatchNode = null
    },
    handleClickShowStartWarehouseOptimization() {
      this.warehouseOptimizationDialog = true
    },
    async handleClickWarehouseOptimizationExport() {
      if (!onStockStore.project) return
      if (!onStockStore.onStockModel) return
      if (!onStockStore.warehouseOptimization) return
      if (this.isCompare && !onStockStore.warehouseOptimizationSecondary) return

      await exportWarehouseOptimization(
        onStockStore.project.id.toString(),
        onStockStore.onStockModel.id.toString(),
        this.isCompare &&
          !!onStockStore.warehouseOptimizationSecondary &&
          getCompareStateBasedOnFadingLevel() === ModelStateNumber.secondary
          ? onStockStore.warehouseOptimizationSecondary.id.toString()
          : onStockStore.warehouseOptimization.id.toString()
      )
    },
    select: addItemsToSelection,
    remove: removeItemsFromSelection,
  },
})

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    { staticClass: "no-model-msg", attrs: { vertical: "" } },
    [
      _c("cant-load-hall-svg"),
      _c("span", { staticClass: "load-failed-title" }, [
        _vm._v(_vm._s(_vm.$t("Failed to load model"))),
      ]),
      _c("span", { staticClass: "load-failed-message" }, [
        _vm._v(_vm._s(_vm.errorMessage)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      style: `transform: scale(${_vm.scale});`,
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_102_9)" } }, [
        _c("path", {
          attrs: { d: "M11 5L19.6603 18.9286H2.33975L11 5Z", fill: _vm.color },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_102_9" } }, [
          _c("rect", { attrs: { width: "22", height: "22", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    { attrs: { vertical: "", align: "end", justify: "end" } },
    [
      !_vm.item.hidden
        ? _c(
            "div",
            {
              class: [
                "warehouse-optimazitaion-select-tree-node-root",
                "px-2",
                { selected: _vm.selected },
              ],
              style: `--warehouse-optimazitaion-select-tree-node-selected-color: ${_vm.color}`,
              on: {
                mouseover: function ($event) {
                  $event.stopPropagation()
                  _vm.hover = true
                },
                mouseout: function ($event) {
                  _vm.hover = false
                },
                click: _vm.select,
              },
            },
            [
              _c("div", {
                staticClass:
                  "warehouse-optimazitaion-select-tree-node-spacer-multi",
                style: `--warehouse-optimazitaion-select-tree-node-root-level: ${_vm.level}`,
              }),
              _c("span", { staticClass: "select-tree-name flex-grow-1" }, [
                _vm._v(_vm._s(_vm.item.name) + " "),
              ]),
              !_vm.item.hideProgress
                ? _c(
                    "model-state-chip",
                    {
                      attrs: {
                        small: "",
                        compare: _vm.item.compare,
                        compareState: _vm.item.modelState,
                      },
                    },
                    [_vm._v(_vm._s(_vm.item.value.toFixed()) + " mm")]
                  )
                : _vm._e(),
              _vm.item.objectMatchId && _vm.item.deleteMatchHandler
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-1",
                      style: `opacity: ${Number(_vm.hover)}`,
                      attrs: { "x-small": "", icon: "", color: "error" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onClickDeleteMatch.apply(null, arguments)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.item.addUnusedValue !== undefined
        ? _c(
            "cs-flex",
            { staticClass: "align-center px-2" },
            [
              _c("div", {
                staticClass:
                  "warehouse-optimazitaion-select-tree-node-spacer-multi",
                style: `--warehouse-optimazitaion-select-tree-node-root-level: ${_vm.level}`,
              }),
              _c(
                "div",
                { staticClass: "flex-grow-1 grey--text un-used-text" },
                [_vm._v(" " + _vm._s(_vm.$t("Nem felhasznált")) + " ")]
              ),
              _c(
                "v-chip",
                {
                  staticClass: "un-used text-right",
                  attrs: { small: "", label: "" },
                },
                [
                  _c("cs-latex-field", [
                    _vm._v(_vm._s(_vm.item.addUnusedValue.toFixed()) + " mm"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.item.addSeparator
        ? _c(
            "cs-flex",
            [
              _c("div", {
                staticClass:
                  "warehouse-optimazitaion-select-tree-node-spacer-multi",
                style: `--warehouse-optimazitaion-select-tree-node-root-level: ${_vm.level}`,
              }),
              _c("v-divider"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
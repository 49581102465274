
import {
  getLoadsByLoadCase,
  modelViewerStore,
  removeActiveLoad,
  removeSelection,
  setActiveLoad,
  setCurrentCamera,
} from '@/store/modelViewer.store'
import { CompareStates, CsFlex, CsSelectTree, TreeNode } from '@consteel/csuetify'
import { GetAll, LoadCase } from '@consteel/storm'
import Vue from 'vue'

class ParsedLoadCase {
  loadCase?: LoadCase
  compareStates?: CompareStates
}

export default Vue.extend({
  name: 'LoadTab',
  components: {
    CsFlex,
    CsSelectTree,
  },
  props: {},
  data() {
    return {
      selectedItems: [] as string[],
    }
  },
  computed: {
    parsedLoadCases(): ParsedLoadCase[] {
      if (!modelViewerStore.model?.rawSmadsteelModel) return []
      const mergedLoadCases: ParsedLoadCase[] = []
      //lekérjük az összes load caset ami az első modellhez tartozik
      const loadCases = GetAll<LoadCase>(modelViewerStore.model.rawSmadsteelModel, LoadCase)

      //végigmegyünk a load caseken és megnézzük a compare értékeiket, majd hozzáadjuk az együttes listához
      loadCases.forEach((loadCase) => {
        const relevantLoads = getLoadsByLoadCase(
          modelViewerStore.model.rawSmadsteelModel!,
          loadCase.id || ''
        )
        const deleted = this.getIsDeletedLoadCase(loadCase)
        const changed = this.getIsChangedLoadCase(loadCase, relevantLoads)

        const parsedLoadCase = new ParsedLoadCase()
        parsedLoadCase.compareStates = { added: false, changed, deleted }
        // eslint-disable-next-line no-undef
        parsedLoadCase.loadCase = structuredClone(loadCase)

        if (deleted || changed)
          parsedLoadCase.loadCase.name = `${parsedLoadCase.loadCase.name} ${modelViewerStore.model.modelNumber}`

        mergedLoadCases.push(parsedLoadCase)
      })

      //ha nincs compare akkor végeztünk
      if (!modelViewerStore.secondaryModel?.rawSmadsteelModel) return mergedLoadCases

      //lekérjük a második modellhez tartozó load caseket, végigmegyünk rajtuk és az előbbiek szerint updateljük az összesített listát
      const loadCasesSecondary = GetAll<LoadCase>(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        LoadCase
      )

      loadCasesSecondary.forEach((loadCase) => {
        const relevantLoads = getLoadsByLoadCase(
          modelViewerStore.secondaryModel.rawSmadsteelModel!,
          loadCase.id || ''
        )
        const added = this.getIsAddedLoadCase(loadCase)
        const changed = this.getIsChangedLoadCase(loadCase, relevantLoads)

        if (added || changed) {
          const parsedLoadCase = new ParsedLoadCase()
          parsedLoadCase.compareStates = { added, changed, deleted: false }
          // eslint-disable-next-line no-undef
          parsedLoadCase.loadCase = structuredClone(loadCase) //lemásoljuk deep copyval a loadcaset

          if (added || changed)
            parsedLoadCase.loadCase.name = `${parsedLoadCase.loadCase.name} ${modelViewerStore.secondaryModel.modelNumber}`

          mergedLoadCases.push(parsedLoadCase)
        }
      })

      return mergedLoadCases
    },
    items(): TreeNode[] {
      const loadGroupMap = new Map<string | undefined, TreeNode>()
      for (const loadCase of this.parsedLoadCases) {
        const leaf: TreeNode | undefined = loadGroupMap.get(loadCase.loadCase?.loadGroup?.id)

        const child: TreeNode = {
          id: loadCase.loadCase?.id || 'Other',
          name: loadCase.loadCase?.name || 'N/A',
          open: false,
          compareStates: loadCase.compareStates,
        }

        if (!leaf) {
          const loadGroup: TreeNode = {
            id: loadCase.loadCase?.loadGroup?.id ?? 'Other',
            name: loadCase.loadCase?.loadGroup?.name ?? 'N/A',
            children: [child],
            compareStates: loadCase.compareStates,
          }

          loadGroupMap.set(loadCase.loadCase?.loadGroup?.id, loadGroup)
        } else {
          leaf.children?.push(child)
          leaf.compareStates = {
            added: !!(leaf.compareStates?.added || child.compareStates?.added),
            changed: !!(leaf.compareStates?.changed || child.compareStates?.changed),
            deleted: !!(leaf.compareStates?.deleted || child.compareStates?.deleted),
          }
        }
      }
      return Array.from(loadGroupMap.values())
    },
    model() {
      return modelViewerStore.model.rawSmadsteelModel
    },
    showedCompareStates() {
      return modelViewerStore.showedCompareStates
    },
  },
  watch: {
    model() {
      setActiveLoad(this.selectedItems[0])
    },
    showedCompareStates() {
      this.selectedItems = []
    },
  },
  methods: {
    getIsDeletedLoadCase(loadCase: LoadCase): boolean {
      const deleted = !!modelViewerStore.deletedItems?.includes(loadCase.id || '') //TÖrölt a loadcase ha a deleted listában szerepel
      return deleted
    },
    getIsAddedLoadCase(loadCase: LoadCase): boolean {
      const added = !!modelViewerStore.addedItems?.includes(loadCase.id || '') //TÖrölt a loadcase ha a deleted listában szerepel
      return added
    },
    getIsChangedLoadCase(loadCase: LoadCase, relevantLoads: string[]): boolean {
      const changed =
        !this.getIsAddedLoadCase(loadCase) &&
        !this.getIsDeletedLoadCase(loadCase) &&
        (!!modelViewerStore.changedItems?.includes(loadCase.id || '') || //Changed a loadcase, ha szerepel az id-ja a changed listában
          !!relevantLoads?.filter((value) => modelViewerStore.changedItems?.includes(value))
            .length || //VAGY a changed listában szerepel akármelyik loadja
          !!relevantLoads?.filter((value) => modelViewerStore.deletedItems?.includes(value))
            .length || //VAGY a deleted listában szerepel akármelyik loadja
          !!relevantLoads?.filter((value) => modelViewerStore.addedItems?.includes(value)).length) //VAGY az added listában szerepel akármelyik loadja
      return changed
    },
    select(...ids: string[]) {
      //save current camera pose
      setCurrentCamera()
      if (this.selectedItems.length) {
        removeSelection()
      }
      this.selectedItems.push(...ids)
      // SelectTree is single-select
      setActiveLoad(ids[0])
    },
    async remove(...ids) {
      //save current camera pose
      setCurrentCamera()
      // While we wait, the select method runs
      await this.$nextTick()
      this.selectedItems = this.selectedItems.filter((id) => !ids.includes(id))

      if (!this.selectedItems.length) {
        removeActiveLoad()
      }
    },
  },
})

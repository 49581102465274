var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "load-tab",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        justify: "flex-start",
        vertical: "",
      },
    },
    [
      _c(
        "span",
        { staticClass: "load-tab-title pa-5" },
        [
          _vm._v(_vm._s(_vm.$t("Load cases")) + " "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", "max-width": "350px" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            { staticClass: "ml-2", attrs: { size: "16" } },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v("mdi-information-outline")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "The loads in different Load cases can be switched here."
                    )
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("cs-select-tree", {
        attrs: {
          items: _vm.items,
          "single-select": "",
          selectedIds: _vm.selectedItems,
        },
        on: { select: _vm.select, remove: _vm.remove },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
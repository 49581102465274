var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "warehouse-optimazitaion-select-tree-node",
      attrs: { "fill-width": "", justify: "flex-start", vertical: "" },
      on: {
        mouseover: function ($event) {
          $event.stopPropagation()
          _vm.hover = true
        },
        mouseout: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _c(
        "cs-flex",
        {
          class: [
            "warehouse-optimazitaion-select-tree-node-root",
            "px-2",
            { selected: _vm.selected, open: _vm.open },
          ],
          style: `--warehouse-optimazitaion-select-tree-node-selected-color: ${_vm.color}`,
          attrs: { "fill-width": "", justify: "flex-start" },
          on: { click: _vm.select },
        },
        [
          _vm._l(_vm.level, function (index) {
            return [
              _c("div", {
                key: index,
                staticClass: "warehouse-optimazitaion-select-tree-node-spacer",
              }),
            ]
          }),
          _c(
            "cs-flex",
            {
              staticClass: "warehouse-optimazitaion-select-tree-node-icon",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.open = !_vm.open
                },
              },
            },
            [_c("triangle-down-svg")],
            1
          ),
          !_vm.item.children?.length
            ? _c(
                "v-menu",
                {
                  attrs: {
                    right: "",
                    top: "",
                    "close-on-content-click": false,
                    "close-on-click": true,
                    "offset-x": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ attrs }) {
                          return [
                            _c(
                              "span",
                              _vm._b(
                                {
                                  staticClass: "select-tree-name flex-grow-1",
                                  on: {
                                    click: function ($event) {
                                      _vm.showSectionTable = true
                                    },
                                  },
                                },
                                "span",
                                attrs,
                                false
                              ),
                              [_vm._v(_vm._s(_vm.item.name))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3192865070
                  ),
                  model: {
                    value: _vm.showSectionTable,
                    callback: function ($$v) {
                      _vm.showSectionTable = $$v
                    },
                    expression: "showSectionTable",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "section-table" },
                    [
                      _c("info-table", {
                        attrs: {
                          header: _vm.$t("Raktárkészlet információk"),
                          items: _vm.sectionItems,
                          compare: _vm.item.compare,
                          compareItems: _vm.sectionItemsSecondary,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "span",
                { staticClass: "select-tree-name flex-grow-1" },
                [
                  _vm._v(_vm._s(_vm.item.name)),
                  _vm.item.afterIcon
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            color: _vm.item.afterIcon.color,
                            size: _vm.item.afterIcon.size,
                          },
                        },
                        [_vm._v(_vm._s(_vm.item.afterIcon.name))]
                      )
                    : _vm._e(),
                ],
                1
              ),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _vm.isCompare
                        ? _c(
                            "div",
                            { staticClass: "compare-progress-bar" },
                            [
                              _c(
                                "v-progress-linear",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "progress",
                                      attrs: {
                                        value: _vm.progress,
                                        color: "primary",
                                        height: "7",
                                      },
                                    },
                                    "v-progress-linear",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                              _c(
                                "v-progress-linear",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "progress",
                                      attrs: {
                                        value: _vm.progressSecondary,
                                        color: "#dcb21c",
                                        height: "7",
                                      },
                                    },
                                    "v-progress-linear",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ],
                            1
                          )
                        : !_vm.item.hideProgress
                        ? [
                            _vm.item.objectMatchId &&
                            _vm.item.deleteMatchHandler
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-1",
                                    style: `opacity: ${Number(
                                      _vm.hover
                                    )}; visibility: ${
                                      _vm.item.children?.length
                                        ? "visible"
                                        : "hidden"
                                    }`,
                                    attrs: {
                                      "x-small": "",
                                      icon: "",
                                      color: "error",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onClickDeleteMatch.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-close-circle")])],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-progress-linear",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "progress",
                                    attrs: {
                                      value: _vm.progress,
                                      color: "primary",
                                      height: "14",
                                    },
                                  },
                                  "v-progress-linear",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _vm.isCompare
                ? _c("div", [
                    _c("div", [_vm._v(_vm._s(_vm.primaryModelNumber))]),
                    _c(
                      "div",
                      [
                        _c("cs-latex-field", [
                          _vm._v(_vm._s(_vm.usedValue.toFixed()) + " mm"),
                        ]),
                        _vm._v(" / "),
                        _c("cs-latex-field", [
                          _vm._v(_vm._s(_vm.item.value.toFixed()) + " mm"),
                        ]),
                        _vm._v(" ("),
                        _c("cs-latex-field", [
                          _vm._v(_vm._s(_vm.progress.toFixed(2)) + "\\%"),
                        ]),
                        _vm._v(") "),
                      ],
                      1
                    ),
                    _vm.item.quantity
                      ? _c(
                          "div",
                          [
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.usedQuantity.toFixed()) + " db"
                              ),
                            ]),
                            _vm._v(" / "),
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.item.quantity.toFixed()) + " db"
                              ),
                            ]),
                            _vm._v(" ("),
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.quantityProgress?.toFixed(2)) + "\\%"
                              ),
                            ]),
                            _vm._v(") "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c("div", [_vm._v(_vm._s(_vm.secondaryModelNumber))]),
                        _c("cs-latex-field", [
                          _vm._v(
                            _vm._s(_vm.usedValueSecondary.toFixed()) + " mm"
                          ),
                        ]),
                        _vm._v(" / "),
                        _c("cs-latex-field", [
                          _vm._v(_vm._s(_vm.item.value.toFixed()) + " mm"),
                        ]),
                        _vm._v(" ("),
                        _c("cs-latex-field", [
                          _vm._v(
                            _vm._s(_vm.progressSecondary.toFixed(2)) + "\\%"
                          ),
                        ]),
                        _vm._v(") "),
                      ],
                      1
                    ),
                    _vm.item.quantity
                      ? _c(
                          "div",
                          [
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.usedQuantitySecondary.toFixed()) +
                                  " db"
                              ),
                            ]),
                            _vm._v(" / "),
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.item.quantity.toFixed()) + " db"
                              ),
                            ]),
                            _vm._v(" ("),
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(
                                  _vm.quantityProgressSecondary?.toFixed(2)
                                ) + "\\%"
                              ),
                            ]),
                            _vm._v(") "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _c("div", [
                    _vm.isSupplyOptTooltip && _vm.item.quantity
                      ? _c(
                          "div",
                          [
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.item.quantity.toFixed()) + " db"
                              ),
                            ]),
                            _vm._v(" ("),
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.unUsedProgress.toFixed(2)) + "\\%"
                              ),
                            ]),
                            _vm._v(") "),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("cs-latex-field", [
                              _vm._v(_vm._s(_vm.usedValue.toFixed()) + " mm"),
                            ]),
                            _vm._v(" / "),
                            _c("cs-latex-field", [
                              _vm._v(_vm._s(_vm.item.value.toFixed()) + " mm"),
                            ]),
                            _vm._v(" ("),
                            _c("cs-latex-field", [
                              _vm._v(_vm._s(_vm.progress.toFixed(2)) + "\\%"),
                            ]),
                            _vm._v(") "),
                          ],
                          1
                        ),
                    _vm.item.quantity && !_vm.isSupplyOptTooltip
                      ? _c(
                          "div",
                          [
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.usedQuantity.toFixed()) + " db"
                              ),
                            ]),
                            _vm._v(" / "),
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.item.quantity.toFixed()) + " db"
                              ),
                            ]),
                            _vm._v(" ("),
                            _c("cs-latex-field", [
                              _vm._v(
                                _vm._s(_vm.quantityProgress?.toFixed(2)) + "\\%"
                              ),
                            ]),
                            _vm._v(") "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
            ]
          ),
        ],
        2
      ),
      _vm.open
        ? _c(
            "cs-flex",
            {
              staticClass: "warehouse-optimazitaion-select-tree-node-children",
              attrs: { vertical: "" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center px-2" },
                [
                  _vm._l(_vm.level + 1, function (index) {
                    return [
                      _c("div", {
                        key: index,
                        staticClass:
                          "warehouse-optimazitaion-select-tree-node-spacer",
                      }),
                    ]
                  }),
                  !_vm.item.hideProgress
                    ? _c(
                        "div",
                        { staticClass: "flex-grow-1 grey--text un-used-text" },
                        [_vm._v(" " + _vm._s(_vm.$t("Nem felhasznált")) + " ")]
                      )
                    : _vm._e(),
                  !_vm.item.hideProgress
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "un-used text-right",
                          attrs: { small: "", label: "" },
                        },
                        [
                          _c("cs-latex-field", [
                            _vm._v(_vm._s(_vm.unUsed.toFixed()) + " mm"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._t("default"),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
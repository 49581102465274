var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "filter-tab",
      attrs: { "fill-height": "", "fill-width": "", vertical: "" },
    },
    [
      _c(
        "span",
        { staticClass: "filter-radio-group-title px-5 pt-5" },
        [
          _vm._v(" " + _vm._s(_vm.$t("Members by")) + " "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", "max-width": "350px" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            { staticClass: "ml-2", attrs: { size: "16" } },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v("mdi-information-outline")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "Select multiple members by materials and section types or use Ctrl + left mouse within the model to select a model and view its properties."
                    )
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "cs-radio-group",
        {
          attrs: { row: "" },
          model: {
            value: _vm.filter,
            callback: function ($$v) {
              _vm.filter = $$v
            },
            expression: "filter",
          },
        },
        [
          _c(
            "cs-flex",
            {
              staticClass: "radio-container px-5",
              attrs: { "fill-width": "", justify: "flex-start", gap: "3rem" },
            },
            [
              _c("cs-radio", { attrs: { label: _vm.$t("Section"), value: 0 } }),
              _c("cs-radio", {
                attrs: { label: _vm.$t("Material"), value: 1 },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("cs-select-tree", {
        staticClass: "filter-tree",
        attrs: { items: _vm.items, selectedIds: _vm.selectedIds },
        on: { select: _vm.select, remove: _vm.remove },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_9_230)" } }, [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M5.88672 16.7619L2.99997 22L0.11322 16.7619H2.49997V10.4762H3.49997V16.7619H5.88672Z",
            fill: _vm.color,
          },
        }),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M13.6602 16.7619L10.7735 22L7.88672 16.7619H10.2735V5.2381H11.2735V16.7619H13.6602Z",
            fill: _vm.color,
          },
        }),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M21.4337 16.7619L18.547 22L15.6602 16.7619H18.047V0L19.047 2.18062e-08V16.7619H21.4337Z",
            fill: _vm.color,
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_9_230" } }, [
          _c("rect", {
            attrs: { width: "100%", height: "100%", fill: "white" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    staticClass: "comment-type-select",
    attrs: {
      dense: "",
      "hide-details": "",
      flat: "",
      color: "inherit",
      "background-color": "inherit",
      "item-color": "inherit",
      solo: "",
      "append-icon": "mdi-chevron-down",
      items: _vm.commentTypeItems,
      "item-text": "displayText",
      "item-value": "value",
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "comment-type-select-label" }, [
              _vm._v(_vm._s(item.displayText)),
            ]),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "cs-flex",
              { staticClass: "comment-type-select-item-cont" },
              [
                _c("div", { staticClass: "comment-type-select-item-text" }, [
                  _vm._v(_vm._s(item.displayText)),
                ]),
                item.displayNumber > 0
                  ? _c("v-badge", {
                      staticClass: "comment-type-select-item-badge",
                      attrs: { content: item.displayNumber, inline: "" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.commentSelectType,
      callback: function ($$v) {
        _vm.commentSelectType = $$v
      },
      expression: "commentSelectType",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
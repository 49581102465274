
import Vue from 'vue'
import { CsSimpleDialog } from '@consteel/csuetify'
import { WarehouseOptimizationDialogPopUpRefs } from './WarehouseOptimizationDialog.vue'

export default Vue.extend({
  name: 'WarehouseOptimizationDialogPopUp',
  components: {
    CsSimpleDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    maxWidth: { type: Number, default: 750 },
  },
  data() {
    return { WarehouseOptimizationDialogPopUpRefs }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value
      },
      set(newVal: boolean): void {
        this.$emit('input', newVal)
      },
    },
  },
  methods: {
    scrollToRef(scrollToRef: string): void {
      setTimeout(() => {
        if (this.$refs[scrollToRef] instanceof HTMLElement) {
          ;(this.$refs[scrollToRef] as HTMLElement).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }, 100)
    },
  },
})

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      style: `transform: scale(${_vm.scale});`,
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.8986 16.9615C2.95581 16.751 2.82937 16.4582 2.7087 16.2471C2.67112 16.1842 2.63036 16.1232 2.58658 16.0644C1.55162 14.4948 0.999981 12.6561 1.00005 10.776C0.98322 5.37933 5.45821 1 10.9919 1C15.8178 1 19.8462 4.34327 20.7875 8.78125C20.9285 9.43923 20.9998 10.1102 21 10.7832C21 16.1875 16.6976 20.6356 11.164 20.6356C10.2842 20.6356 9.09666 20.4144 8.44907 20.2332C7.80147 20.0519 7.15484 19.8115 6.98801 19.7471C6.81739 19.6815 6.63619 19.6478 6.4534 19.6476C6.25374 19.6468 6.05601 19.6866 5.87215 19.7644L2.6111 20.9413C2.53966 20.9721 2.46396 20.9919 2.38658 21C2.32552 20.9998 2.26511 20.9875 2.20884 20.9638C2.15256 20.9401 2.10155 20.9055 2.05876 20.8619C2.01597 20.8184 1.98224 20.7668 1.95953 20.7101C1.93683 20.6534 1.9256 20.5928 1.92649 20.5317C1.9305 20.4781 1.94017 20.4251 1.95533 20.3736L2.8986 16.9615Z",
          stroke: _vm.color,
          "stroke-width": "2",
          "stroke-miterlimit": "10",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
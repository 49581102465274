
import Vue from 'vue'
import { CsFlex } from '@consteel/csuetify'
import CantLoadHallSvg from '@/assets/CantLoadHall.svg.vue'

export default Vue.extend({
  name: 'FailedToLoadModel',
  components: {
    CantLoadHallSvg,
    CsFlex,
  },

  props: {
    errorMessage: String,
  },
})

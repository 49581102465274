
import Vue from 'vue'
import {
  modelViewerStore,
  setClipPlaneX,
  setClipPlaneY,
  setClipPlaneZ,
  setHiddenParamOnGuiObjects,
  toggleClipPlane,
} from '../../store/modelViewer.store'
import { CsSlider } from '@consteel/csuetify'

export default Vue.extend({
  name: 'ClipPlane',
  components: {
    CsSlider,
  },
  computed: {
    clipSliderMax(): number {
      return 100
    },
    clipSliderMin(): number {
      return 0
    },
    clipPlane(): boolean {
      return modelViewerStore.clipPlaneVisibility
    },
    clipPlaneX(): number {
      return modelViewerStore.clipPlaneX
    },
    clipPlaneY(): number {
      return modelViewerStore.clipPlaneY
    },
    clipPlaneZ(): number {
      return modelViewerStore.clipPlaneZ
    },
  },
  methods: {
    handleToggleClipPlane(event: any): void {
      toggleClipPlane(event.target.checked)
      if (!modelViewerStore.currentView) return

      if (!this.clipPlane) {
        modelViewerStore.currentView.ClearClipPlaneX()
        modelViewerStore.currentView.ClearClipPlaneY()
        modelViewerStore.currentView.ClearClipPlaneZ()
      } else {
        modelViewerStore.currentView.SetClipPlaneX(this.clipPlaneX)
        modelViewerStore.currentView.SetClipPlaneY(this.clipPlaneY)
        modelViewerStore.currentView.SetClipPlaneZ(this.clipPlaneZ)
      }
      setHiddenParamOnGuiObjects()
    },
    clipSliderX(value: number): void {
      modelViewerStore.currentView?.SetClipPlaneX(value)
      setClipPlaneX(value)
      setHiddenParamOnGuiObjects()
    },
    clipSliderY(value: number): void {
      modelViewerStore.currentView?.SetClipPlaneY(value)
      setClipPlaneY(value)
      setHiddenParamOnGuiObjects()
    },
    clipSliderZ(value: number): void {
      modelViewerStore.currentView?.SetClipPlaneZ(value)
      setClipPlaneZ(value)
      setHiddenParamOnGuiObjects()
    },
  },
})


import Vue from 'vue'
import { PropType } from 'vue/types/v3-component-props'
import { ModelStateNumber } from '../../store/modelViewer.store'
export default Vue.extend({
  name: 'ModelStateChip',
  props: {
    compareState: {
      type: Number as PropType<ModelStateNumber>,
      default: ModelStateNumber.primary,
    },
    compare: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedCompareClass(): string {
      if (this.compare) {
        return this.compareState === ModelStateNumber.primary ? 'primary' : 'secondary'
      }

      return ''
    },
  },
})

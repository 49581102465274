
import { CsCommentInput, CsCommentBubble } from '@consteel/csuetify'
import Vue from 'vue'
import { createComment } from '@/modules/comment'
import { getModelByIdSubscribe, SteelspaceModel } from '@/modules/model'
import {
  addFloatingLabels,
  getCommentSettings,
  modelViewerStore,
  removeFloatingCommentInputs,
  removeSelection,
  setCommentSelectTypeBySelectedIds,
  setCurrentCamera,
} from '../../../store/modelViewer.store'
import { FloatingCommentInput } from '../../../types'

export default Vue.extend({
  name: 'FloatingCommentInput',
  components: {
    CsCommentBubble,
    CsCommentInput,
  },
  props: {
    floatingCommentInput: Object as () => FloatingCommentInput,
  },
  data() {
    return {
      showLabelBubble: true,
      showCommentInput: true,
    }
  },
  methods: {
    async onClickLabelBubble() {
      await addFloatingLabels(
        this.floatingCommentInput.id,
        this.floatingCommentInput.objectTypeName,
        this.floatingCommentInput.position
      )
    },
    async onSaveComment(comment: string, callback: () => void): Promise<void> {
      if (!this.model) {
        console.error('Model not found to make a comment')
        return
      }
      setCurrentCamera()
      const { x, y, z } = modelViewerStore.floatingCommentInputs[0].position

      await createComment(
        comment,
        this.model.isOrigin ? this.modelId : this.model.originModelId,
        getCommentSettings(),
        this.$route.params.historyId,
        modelViewerStore.selectedIds,
        { x, y, z }
      )

      await removeFloatingCommentInputs()
      setCommentSelectTypeBySelectedIds()

      removeSelection()

      this.showCommentInput = true
      callback?.()
    },
  },
  computed: {
    modelId(): string {
      return this.$route.params.modelId
    },
    model(): SteelspaceModel | null {
      return getModelByIdSubscribe(this.modelId)
    },
    loading(): boolean {
      return false //TODO
    },
    isShowAddLabelOption(): boolean {
      return modelViewerStore.labelShowAddLabelOption
    },
    isShowCreateCommentOption(): boolean {
      return modelViewerStore.commentShowCreateCommentOption
    },
  },
  watch: {
    showLabelBubble(value: boolean) {
      if (!value) this.showCommentInput = true
    },
    showCommentInput(value: boolean) {
      if (!value) this.showLabelBubble = true
    },
  },
})

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !!_vm.opacity && _vm.isFloatingCommentVisible,
          expression: "!!opacity && isFloatingCommentVisible",
        },
      ],
      staticClass: "floating-comment",
      style: `--opacity: ${_vm.opacity}; --scale: ${_vm.commentScale};`,
    },
    [
      _c("cs-comment-bubble", {
        attrs: {
          active: _vm.isCommentSelected,
          icon: _vm.commentIcon,
          size: 40,
          iconSize: 20,
          commentNumber: _vm.numberOfUnreadComments,
        },
        on: { click: _vm.onClickFloatingComment },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import { CsFlex, CsLatexField } from '@consteel/csuetify'
import Vue from 'vue'
import { OptimizationTreeTooltipType, WarehouseOptimizationTreeNode } from '.'
import TriangleDownSvg from '../../../assets/viewer/TriangleDown.svg.vue'
import { latex_units } from '../../../services'
import { modelViewerStore } from '../../../store/modelViewer.store'
import InfoTable from '../Info/InfoTable.vue'
import { TableNode } from '../Info/TableNode'

export default Vue.extend({
  name: 'SelectTreeNode',
  components: {
    CsFlex,
    CsLatexField,
    TriangleDownSvg,
    InfoTable,
  },
  props: {
    usedQuantity: {
      type: Number,
    },
    item: {
      type: Object as () => WarehouseOptimizationTreeNode,
      required: true,
    },
    usedValue: {
      type: Number,
    },
    usedQuantitySecondary: {
      type: Number,
    },
    usedValueSecondary: {
      type: Number,
    },
    level: {
      type: Number,
      default: 0,
    },
    singleSelect: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
    },
    color: {
      type: String,
      default: '#5aaeff',
    },
  },
  data() {
    return {
      open: this.item.open,
      showSectionTable: false,
      hover: false,
    }
  },
  computed: {
    sectionItemsSecondary(): TableNode[] | undefined {
      if (!this.item.compare) return

      return [
        {
          name: this.$t('Össz db'),
          value: this.item.quantityToCompare,
          unit: 'db',
        },
        {
          name: this.$t('Össz hossz'),
          value: this.item.valueToCompare,
          unit: latex_units.mm,
        },
        {
          name: this.$t('Elemcsoportok'), //TODO
          value: ' ',
          children: this.item.warehouseSummaryItemBlocksToCompare?.map((itemBlock) => ({
            name: itemBlock.length.toString() + ' mm',
            value: itemBlock.quantity,
            unit: 'db',
          })),
        },
      ]
    },
    sectionItems(): TableNode[] {
      return [
        {
          name: this.$t('Össz db'),
          value: this.item.quantity,
          unit: 'db',
        },
        {
          name: this.$t('Össz hossz'),
          value: this.item.value,
          unit: latex_units.mm,
        },
        {
          name: this.$t('Elemcsoportok'),
          value: ' ',
          children: this.item.warehouseSummaryItemBlocks?.map((itemBlock) => ({
            name: itemBlock.length.toString() + ' mm',
            value: itemBlock.quantity,
            unit: 'db',
          })),
        },
      ]
    },
    primaryModelNumber(): string {
      return modelViewerStore.model.modelNumber || ''
    },
    secondaryModelNumber(): string {
      return modelViewerStore.secondaryModel.modelNumber || ''
    },
    isCompare(): boolean {
      return !!this.item.compare
    },
    quantityProgress(): number | undefined {
      return this.item.quantity ? (this.usedQuantity / this.item.quantity) * 100 : undefined
    },
    quantityProgressSecondary(): number | undefined {
      return this.item.quantity && this.usedQuantitySecondary != null
        ? (this.usedQuantitySecondary / this.item.quantity) * 100
        : undefined
    },
    progress(): number {
      return (this.usedValue / this.item.value) * 100
    },
    progressSecondary(): number {
      return (this.usedValueSecondary / this.item.value) * 100
    },
    unUsed(): number {
      return this.item.value - this.usedValue
    },
    unUsedProgress(): number {
      return 100 - this.progress
    },
  },
  methods: {
    isSupplyOptTooltip(item: WarehouseOptimizationTreeNode): boolean {
      return item.tooltipType === OptimizationTreeTooltipType.SupplyOpt
    },
    select() {
      // Single Selection
      if (this.singleSelect) {
        this.open = !this.open
        return
      }
      this.$emit('select', this.item.id)
    },
    onClickDeleteMatch() {
      this.$emit('delete')
    },
  },
})

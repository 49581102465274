
import Vue from 'vue'
import { CsFlex } from '@consteel/csuetify'
import ChatSvg from '@/assets/viewer/Chat.svg.vue'

export default Vue.extend({
  name: 'ChatWithBadge',
  components: {
    CsFlex,
    ChatSvg,
  },
  props: {
    badgeColor: {
      required: false,
      default: '#EB3223',
      type: String,
    },
    badgeContent: {
      required: false,
      default: 0,
      type: Number,
    },
  },
})

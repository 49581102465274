var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-chip",
    {
      class: [
        "model-state-chip",
        _vm.computedCompareClass,
        _vm.small ? "small" : undefined,
      ],
      attrs: { small: "", label: "" },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      style: `transform: scale(${_vm.scale});`,
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.4551 16.6689V11.126C13.4551 10.7106 13.3799 10.3525 13.2295 10.0518C13.0791 9.75098 12.8499 9.51823 12.542 9.35352C12.2412 9.1888 11.8617 9.10645 11.4033 9.10645C10.9808 9.10645 10.6156 9.17806 10.3076 9.32129C9.99967 9.46452 9.75977 9.65788 9.58789 9.90137C9.41602 10.1449 9.33008 10.4206 9.33008 10.7285H6.75195C6.75195 10.2702 6.86296 9.82617 7.08496 9.39648C7.30697 8.9668 7.62923 8.58366 8.05176 8.24707C8.47428 7.91048 8.97917 7.64551 9.56641 7.45215C10.1536 7.25879 10.8125 7.16211 11.543 7.16211C12.4167 7.16211 13.1901 7.30892 13.8633 7.60254C14.5436 7.89616 15.0771 8.34017 15.4639 8.93457C15.8577 9.52181 16.0547 10.2594 16.0547 11.1475V16.3145C16.0547 16.8444 16.0905 17.3206 16.1621 17.7432C16.2409 18.1585 16.3519 18.5202 16.4951 18.8281V19H13.8418C13.7201 18.7207 13.6234 18.3662 13.5518 17.9365C13.4873 17.4997 13.4551 17.0771 13.4551 16.6689ZM13.8311 11.9316L13.8525 13.5322H11.9941C11.5143 13.5322 11.0918 13.5788 10.7266 13.6719C10.3613 13.7578 10.057 13.8867 9.81348 14.0586C9.56999 14.2305 9.38737 14.4382 9.26562 14.6816C9.14388 14.9251 9.08301 15.2008 9.08301 15.5088C9.08301 15.8167 9.15462 16.0996 9.29785 16.3574C9.44108 16.6081 9.64876 16.805 9.9209 16.9482C10.2002 17.0915 10.5368 17.1631 10.9307 17.1631C11.4606 17.1631 11.9225 17.0557 12.3164 16.8408C12.7174 16.6188 13.0326 16.3503 13.2617 16.0352C13.4909 15.7129 13.6126 15.4085 13.627 15.1221L14.4648 16.2715C14.3789 16.5651 14.2321 16.8802 14.0244 17.2168C13.8167 17.5534 13.5446 17.8757 13.208 18.1836C12.8786 18.4844 12.4811 18.7314 12.0156 18.9248C11.5573 19.1182 11.0273 19.2148 10.4258 19.2148C9.66667 19.2148 8.98991 19.0645 8.39551 18.7637C7.80111 18.4557 7.33561 18.0439 6.99902 17.5283C6.66243 17.0055 6.49414 16.4147 6.49414 15.7559C6.49414 15.14 6.60872 14.5957 6.83789 14.123C7.07422 13.6432 7.41797 13.2422 7.86914 12.9199C8.32747 12.5977 8.88607 12.3542 9.54492 12.1895C10.2038 12.0176 10.9557 11.9316 11.8008 11.9316H13.8311Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import Vue from 'vue'
import {
  CommentSelectType,
  ModelStateNumber,
  modelViewerStore,
  removeSelection,
  setCommentSelectType,
  setFloatingComments,
  setSelectedCommentIds,
} from '../../../store/modelViewer.store'
import { CsFlex } from '@consteel/csuetify'
import { commentStore } from '@/modules/comment'
import { authStore } from '@/modules/auth'
export default Vue.extend({
  name: 'CommentTypeSelect',
  components: {
    CsFlex,
  },
  methods: {
    isCommentUnread(commentId: string): boolean {
      const relevantUnread = commentStore.unreads.find((unread) => unread.commentId === commentId)
      return !!relevantUnread
    },
  },
  computed: {
    numberOfUnreadCommentsBasedOnCommentTypes(): Record<CommentSelectType, number> {
      const numberOfComments: Record<CommentSelectType, number> = {
        [CommentSelectType.GLOBAL]: 0,
        [CommentSelectType.OBJECT_SPECIFIC]: 0,
        [CommentSelectType.OBJECT_GROUP_SPECIFIC]: 0,
      }

      commentStore.comments.map((comment) => {
        if (this.isCommentUnread(comment.id)) {
          if (comment.smadsteelIds.length === 0) {
            numberOfComments[CommentSelectType.GLOBAL]++
          } else if (comment.smadsteelIds.length === 1) {
            numberOfComments[CommentSelectType.OBJECT_SPECIFIC]++
          } else if (comment.smadsteelIds.length > 1) {
            numberOfComments[CommentSelectType.OBJECT_GROUP_SPECIFIC]++
          }
        }
      })
      return numberOfComments
    },
    commentSelectType: {
      get: () => {
        return modelViewerStore.commentSelectType
      },
      set: (value: CommentSelectType) => {
        setCommentSelectType(value)
      },
    },
    commentTypeItems(): Array<{
      displayText: string
      displayNumber: number
      value: CommentSelectType
    }> {
      const unreadComments: Record<CommentSelectType, number> =
        this.numberOfUnreadCommentsBasedOnCommentTypes
      return [
        {
          displayText: this.$t('Global').toString(),
          displayNumber: unreadComments[CommentSelectType.GLOBAL],
          value: CommentSelectType.GLOBAL,
        },
        {
          displayText: this.$t('Object specific').toString(),
          displayNumber: unreadComments[CommentSelectType.OBJECT_SPECIFIC],
          value: CommentSelectType.OBJECT_SPECIFIC,
        },
        {
          displayText: this.$t('Object group specific').toString(),
          displayNumber: unreadComments[CommentSelectType.OBJECT_GROUP_SPECIFIC],
          value: CommentSelectType.OBJECT_GROUP_SPECIFIC,
        },
      ]
    },
  },
  watch: {
    commentSelectType() {
      setFloatingComments()
      setFloatingComments(ModelStateNumber.secondary)
      setSelectedCommentIds([])
      removeSelection()
    },
  },
})

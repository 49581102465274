var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "pa-5" }, [
    _c(
      "div",
      { staticClass: "mr-auto mb-3" },
      [
        _c("input", {
          attrs: { type: "checkbox" },
          domProps: { checked: _vm.clipPlane },
          on: { change: _vm.handleToggleClipPlane },
        }),
        _c("span", { staticClass: "pb-2 ml-3" }, [
          _vm._v(_vm._s(_vm.$t("Clip plane"))),
        ]),
        _c(
          "v-tooltip",
          {
            attrs: { bottom: "", "max-width": "350px" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-icon",
                      _vm._g(
                        _vm._b(
                          { staticClass: "ml-2", attrs: { size: "16" } },
                          "v-icon",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_vm._v("mdi-information-outline")]
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("Adjust the scaling for clip plane"))),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _c("div", [_vm._v("x:")]),
        _c("cs-slider", {
          staticClass: "px-5 flex-grow-1",
          attrs: {
            min: _vm.clipSliderMin,
            max: _vm.clipSliderMax,
            value: _vm.clipPlaneX,
            disabled: !_vm.clipPlane,
          },
          on: { input: _vm.clipSliderX },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _c("div", [_vm._v("y:")]),
        _c("cs-slider", {
          staticClass: "px-5 flex-grow-1",
          attrs: {
            disabled: !_vm.clipPlane,
            min: _vm.clipSliderMin,
            max: _vm.clipSliderMax,
            value: _vm.clipPlaneY,
          },
          on: { input: _vm.clipSliderY },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _c("div", [_vm._v("z:")]),
        _c("cs-slider", {
          staticClass: "px-5 flex-grow-1",
          attrs: {
            min: _vm.clipSliderMin,
            max: _vm.clipSliderMax,
            disabled: !_vm.clipPlane,
            value: _vm.clipPlaneZ,
          },
          on: { input: _vm.clipSliderZ },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
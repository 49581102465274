
import Vue from 'vue'
export default Vue.extend({
  name: 'CantLoadHallSvg',
  props: {
    color: {
      default: 'currentColor',
      type: String,
    },
  },
})

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        title: _vm.$t("Az algoritmus működése"),
        persistent: "",
        maxWidth: _vm.maxWidth,
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c("div", { staticClass: "warehouse-optimization-dialog-pop-up px-3" }, [
        _c("h4", { staticClass: "mb-3" }, [
          _vm._v(_vm._s(_vm.$t("Megfeleltetési algoritmus"))),
        ]),
        _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "A teljes megfeleltetési algoritmus priorizálásból és elem megfeleltetésből áll. Egy előkészítő lépésben beolvassuk a UI-on keresztül megadható excel fájlból a Consteel modell szempontjából releváns raktár elemeket. Releváns raktárelemek azok, aminek a profilja megtalálható a Consteel modellben is, aktív használatban lévő szelvényként. Későbbiekben erre a beolvasott raktár elem készletre hivatkozunk, mint teljes raktárelem készlet."
                )
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "mt-3" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "A priorizálást követően kialakult raktárelem készleten sorrendben, egyesével történik az elem megfeleltetés."
                )
              ) +
              " "
          ),
        ]),
        _c(
          "h4",
          {
            ref: _vm.WarehouseOptimizationDialogPopUpRefs.Priority,
            staticClass: "mb-3 mt-5",
          },
          [_vm._v(" " + _vm._s(_vm.$t("Priorizálás")) + " ")]
        ),
        _c("div", [_vm._v(" " + _vm._s(_vm.$t("Inputok:")) + " ")]),
        _c("ul", [
          _c("li", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "elsődleges priorizálás módja – lent felsorolt paraméterek közül"
                )
              )
            ),
          ]),
          _c("li", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "másodlagos priorizálás módja - lent felsorolt paraméterek közül"
                )
              )
            ),
          ]),
        ]),
        _c("div", { staticClass: "mt-3" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "A priorizálás a raktárelemek csökkenő vagy növekvő sorrendbe való rendezését jelenti az alább felsorolt, UI-ról megadható paraméterek szerint:"
                )
              ) +
              " "
          ),
        ]),
        _c("ul", [
          _c("li", [_vm._v(_vm._s(_vm.$t("Raktárelem hossza")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t(`"Bevét" dátuma`)))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("Raktárelem súlya")))]),
        ]),
        _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "A priorizálás két lépésből áll, egy elsődleges és egy másodlagos priorizálásból. Az elsődleges priorizálás a Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam turpis lorem, fermentum quis lacus at, convallis porttitor nulla. A priorizálás két lépésből áll, egy elsődleges és egy másodlagos priorizálásból. Az elsődleges priorizálás a Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam turpis lorem, fermentum quis lacus at, convallis porttitor nulla. A priorizálás két lépésből áll, egy elsődleges és egy másodlagos priorizálásból. Az elsődleges priorizálás a Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam turpis lorem, fermentum quis lacus at, convallis porttitor nulla. A priorizálás két lépésből áll, egy elsődleges és egy másodlagos priorizálásból. Az elsődleges priorizálás a Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam turpis lorem, fermentum quis lacus at, convallis porttitor nulla. A priorizálás két lépésből áll, egy elsődleges és egy másodlagos priorizálásból. Az elsődleges priorizálás a Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam turpis lorem, fermentum quis lacus at, convallis porttitor nulla."
                )
              ) +
              " "
          ),
        ]),
        _c(
          "h4",
          {
            ref: _vm.WarehouseOptimizationDialogPopUpRefs
              .WarehouseOptimizationMode,
            staticClass: "mb-3 mt-5",
          },
          [_vm._v(" " + _vm._s(_vm.$t("Megfeleltetési mód")) + " ")]
        ),
        _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Praesent diam dolor, gravida vel dolor sed, tempor preCras nec viverra est. Curabitur eros lorem, tempus ut accumsan eget, aliquet malesuada dolor. Nulla vitae ante non quam scelerisque euismod nec vitae metus. Sed sodales nibh sodales, hendrerit purus ac, dictum semtium urna. Ut eleifend sollicitudin ultrices. Donec at sagittis mauris. Vestibulum rhoncus faucibus sem, sed commodo nisl faucibus vitae."
                )
              ) +
              " "
          ),
        ]),
        _c(
          "h4",
          {
            ref: _vm.WarehouseOptimizationDialogPopUpRefs.Tolerance,
            staticClass: "mb-3 mt-5",
          },
          [_vm._v(" " + _vm._s(_vm.$t("Tolerancia")) + " ")]
        ),
        _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Sed pulvinar, justo non egestas laoreet, libero sapien dignissim sem, ut gravida neque erat vitae erat. Nam elit lacus, commodo et imperdiet ac, convallis a leo. Mauris pulvinar malesuada odio, vel pharetra mauris accumsan ut. Cras nec viverra est. Curabitur eros lorem, tempus ut accumsan eget, aliquet malesuada dolor. Nulla vitae ante non quam scelerisque euismod nec vitae metus. Sed sodales nibh sodales, hendrerit purus ac, dictum sem."
                )
              ) +
              " "
          ),
        ]),
        _c("ul", [
          _c("li", [_vm._v(_vm._s(_vm.$t("Raktárelem hossza")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t(`"Bevét" dátuma`)))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("Raktárelem súlya")))]),
        ]),
        _c("div", { staticClass: "mt-3" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Sed pulvinar, justo non egestas laoreet, libero sapien dignissim sem, ut gravida neque erat vitae erat. Nam elit lacus, commodo et imperdiet ac, convallis a leo. Mauris pulvinar malesuada odio, vel pharetra mauris accumsan ut."
                )
              ) +
              " "
          ),
        ]),
        _c(
          "h4",
          {
            ref: _vm.WarehouseOptimizationDialogPopUpRefs.Match,
            staticClass: "mb-3 mt-5",
          },
          [_vm._v(" " + _vm._s(_vm.$t("Pontos egyezés")) + " ")]
        ),
        _c("div", { staticClass: "pb-5" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Nam ultricies placerat ipsum in elementum. Phasellus quam odio, placerat rutrum auctor vitae, malesuada sit amet nisl. Quisque placerat pharetra nunc a cursus. Nam ultricies placerat ipsum in elementum. Phasellus quam odio, placerat rutrum auctor vitae, malesuada sit amet nisl. Quisque placerat pharetra nunc a cursus. Nam ultricies placerat ipsum in elementum. Phasellus quam odio, placerat rutrum auctor vitae, malesuada sit amet nisl. Quisque placerat pharetra nunc a cursus. Nam ultricies placerat ipsum in elementum. Phasellus quam odio, placerat rutrum auctor vitae, malesuada sit amet nisl. Quisque placerat pharetra nunc a cursus."
                )
              ) +
              " "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      staticClass: "triangle-down-svg",
      attrs: {
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [_c("path", { attrs: { d: "M7 10L12 15L17 10H7Z", fill: _vm.color } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M11 6C14.79 6 18.17 8.13 19.82 11.5C18.17 14.87 14.79 17 11 17C7.21 17 3.83 14.87 2.18 11.5C3.83 8.13 7.21 6 11 6ZM11 4C6 4 1.73 7.11 0 11.5C1.73 15.89 6 19 11 19C16 19 20.27 15.89 22 11.5C20.27 7.11 16 4 11 4ZM11 9C12.38 9 13.5 10.12 13.5 11.5C13.5 12.88 12.38 14 11 14C9.62 14 8.5 12.88 8.5 11.5C8.5 10.12 9.62 9 11 9ZM11 7C8.52 7 6.5 9.02 6.5 11.5C6.5 13.98 8.52 16 11 16C13.48 16 15.5 13.98 15.5 11.5C15.5 9.02 13.48 7 11 7Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
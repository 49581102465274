
import { CsFlex, CsLatexField } from '@consteel/csuetify'
import Vue from 'vue'
import { WarehouseOptimizationTreeNode } from '.'
import ModelStateChip from '../ModelStateChip.vue'

export default Vue.extend({
  name: 'SelectTreeNodeWithoutChildren',
  components: {
    ModelStateChip,
    CsFlex,
    CsLatexField,
  },
  data() {
    return { hover: false }
  },
  props: {
    item: {
      type: Object as () => WarehouseOptimizationTreeNode,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#5aaeff',
    },
  },
  methods: {
    select() {
      this.$emit('select', this.item.id)
    },
    onClickDeleteMatch() {
      this.$emit('delete')
    },
  },
})

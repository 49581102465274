var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "floating-comment-input" }, [
    _c(
      "div",
      { staticClass: "label-input" },
      [
        _vm.showLabelBubble && _vm.isShowAddLabelOption
          ? _c("cs-comment-bubble", {
              attrs: {
                icon: "AddLabelIcon",
                size: 50,
                iconSize: 30,
                backgroundColor: "primary",
                tooltip: _vm.$t("Label object"),
              },
              on: { click: _vm.onClickLabelBubble },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm.isShowCreateCommentOption
      ? _c(
          "div",
          { staticClass: "comment-input" },
          [
            _c("cs-comment-input", {
              attrs: {
                sendBtnLoading: _vm.loading,
                tooltip: _vm.$t("Write comment to object"),
              },
              on: { sendBtnClicked: _vm.onSaveComment },
              model: {
                value: _vm.showCommentInput,
                callback: function ($$v) {
                  _vm.showCommentInput = $$v
                },
                expression: "showCommentInput",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }